import Ampli from 'assets/icons/benefitIcons/ampli.svg';
import DisneyPlus from 'assets/icons/benefitIcons/disney+.png';
import ebook from 'assets/icons/benefitIcons/ebook.svg';
import newsStand from 'assets/icons/benefitIcons/newsStand.svg';
import unlimitedSocialNetworks from 'assets/icons/benefitIcons/unlimitedSocialNetworks.svg';
import threeMonthsSocialNetworks from 'assets/icons/benefitIcons/threeMonthsSocialNetworks.svg';
import unlimitedTalks from 'assets/icons/benefitIcons/unlimitedTalks.svg';
import Ensinah from 'assets/icons/benefitIcons/ensinahEnsinah.svg';
import EnsinahEquilibrah from 'assets/icons/benefitIcons/equilibrah.png';
import DeezerPremium from 'assets/icons/benefitIcons/deezer.png';
import DeezerNow from 'assets/icons/benefitIcons/deezer-now.png';
import YoutubePremium from 'assets/icons/benefitIcons/youtube.png';
import HBOMax from 'assets/icons/benefitIcons/max.png';
import ComboDisneyEStar from 'assets/icons/benefitIcons/comboDisneyEStar.png';
import AppleTV from 'assets/icons/benefitIcons/appleTV.png';
import AmazonPrime from 'assets/icons/benefitIcons/prime.png';
import Paramount from 'assets/icons/benefitIcons/paramount+.png';
import Netflix from 'assets/icons/benefitIcons/netflix.png';
import GloboPlay from 'assets/icons/benefitIcons/globoPlay.png';
import PrimeCelular from 'assets/icons/benefitIcons/prime-mobile.png';
import Audiobooks from 'assets/icons/benefitIcons/books.png';
import EnsinahPremium from 'assets/icons/benefitIcons/ensinah.png';
import Babel from 'assets/icons/benefitIcons/babbel.png';
import Bancah from 'assets/icons/benefitIcons/bancah.png';
import Cloud from 'assets/icons/benefitIcons/cloud.png';
import Gestao from 'assets/icons/benefitIcons/gestao.png';
import Seguranca from 'assets/icons/benefitIcons/seguranca.png';
import TimSeguranca from 'assets/icons/benefitIcons/timSeguranca.png';
import Fluid from 'assets/icons/benefitIcons/fluid.png';
import Mulheres from 'assets/icons/benefitIcons/mulheres.png';
import unlimitedSms from 'assets/icons/benefitIcons/unlimitedSms.svg';
import unlimitedCall from 'assets/icons/benefitIcons/unlimitedCall.svg';
import TimOnPlane from 'assets/icons/benefitIcons/plane.png';
import Wifi from 'assets/icons/benefitIcons/wifi.png';
import RedesSociais from 'assets/icons/benefitIcons/redesSociais.png';
import WhatsApp from 'assets/icons/benefitIcons/whatsapp.png';
import InternetAcumulada from 'assets/icons/benefitIcons/internet-acumulada-icone.png';

const Icons: {
  [key: string]: string;
} = {
  Ampli,
  DisneyPlus,
  ebook,
  Ensinah,
  EnsinahEquilibrah,
  EnsinahPremium,
  DeezerPremium,
  YoutubePremium,
  HBOMax,
  ComboDisneyEStar,
  AppleTV,
  AmazonPrime,
  Paramount,
  Netflix,
  GloboPlay,
  unlimitedSocialNetworks,
  newsStand,
  unlimitedSms,
  unlimitedCall,
  threeMonthsSocialNetworks,
  unlimitedTalks,
  DeezerNow,
  PrimeCelular,
  Audiobooks,
  Babel,
  Bancah,
  Cloud,
  Gestao,
  Seguranca,
  Fluid,
  Mulheres,
  Roaming: TimOnPlane,
  TimOnPlane,
  Wifi,
  RedesSociais,
  WhatsApp,
  InternetAcumulada,
  TimSeguranca,
};
export default Icons;
