import { Close } from '@mui/icons-material';
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { CloseButton } from 'components/StyledComponents';
import { SwitchIOS } from 'components/SwitchIOS';
import { useToast } from 'hooks/toast';
import { Package } from 'models/packages';
import TextInfo from 'pages/Sale/InfoSale/TextInfo';
import { useCallback, useState } from 'react';
import api from 'services/api';
import { usePackageStore } from 'store/packageStore';

import { Container, Button as ButtonDrawer, SaveButton } from './styles';

interface EditPackageProps {
  data: Package;
  closeDrawer(): void;
}

function EditPackage({ data, closeDrawer }: EditPackageProps) {
  const category = usePackageStore(state => state.category);

  const [gb, setGb] = useState(data.gb);
  const [classification, setClassification] = useState(category);
  const [active, setActive] = useState(!!data.ativo);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const handleChangeWithActive = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setActive(event.target.checked);
  };

  const handleChangeCategory = (valor: string) => {
    const classificacao = valor || null;

    setClassification(classificacao);
  };

  const handleSave = useCallback(() => {
    setLoading(true);
    api
      .put(`/core/pacotes/${data.id}`, {
        package_form: {
          categoria: classification,
          gb,
          ativo: active,
        },
      })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Classficação de pacote',
          description: 'Pacote classificado com sucesso',
        });

        closeDrawer();
      })
      .catch(error => {
        let message = error.data.errors[0]?.message;
        if (!message) {
          message = error.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao editar pacote!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [active, addToast, classification, closeDrawer, data.id, gb]);

  return (
    <Container>
      <ButtonDrawer>
        <CloseButton onClick={() => closeDrawer()}>
          <IconButton>
            <Close />
          </IconButton>
        </CloseButton>
      </ButtonDrawer>

      <Grid container spacing={2} style={{ paddingTop: 20 }}>
        <Grid item xs={12} md={6}>
          <TextInfo loading={!data} label="ID" info={data.id.toString()} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo loading={!data} label="Nome" info={data.nome} />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="GB"
            variant="outlined"
            value={gb}
            onChange={e => setGb(e.target.value)}
            type="text"
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Classificação"
            variant="outlined"
            select
            value={classification}
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => handleChangeCategory(e.target.value as string)}
            size="small"
            fullWidth
          >
            <option value="">Sem classificação</option>
            <option value="FLASHSALE">Flash Sales</option>
            <option value="PORTABILIDADE">Portabilidade</option>
            <option value="DEDICADO">Dedicado</option>
          </TextField>
        </Grid>
        <Grid item xs={6} md={12}>
          <FormControlLabel
            style={{ marginLeft: 0 }}
            label={
              <Typography variant="body2">
                {active === true ? 'Inativar pacote' : 'Ativar pacote'}
              </Typography>
            }
            control={
              <SwitchIOS
                size="medium"
                color="secondary"
                checked={active}
                onChange={handleChangeWithActive}
              />
            }
            labelPlacement="end"
          />
        </Grid>
      </Grid>
      <SaveButton>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={loading}
        >
          Salvar
        </Button>
      </SaveButton>
    </Container>
  );
}

export default EditPackage;
