import { Line } from 'react-chartjs-2';
import { HourByHourFetch } from 'fetches/hourByHourFetch';

import { format, parseISO } from 'date-fns';
import palette from 'theme/palette';
import { shade } from 'polished';
import { useReportFilterFlagFetchStore } from 'store/useReportFilterFlagFetchStore';

const dataSetsBase = {
  borderDash: [],
  borderDashOffset: 0.0,
  fill: false,
  lineTension: 0.1,
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 3,
  pointHitRadius: 10,
};
const labels = [
  '00h',
  '01h',
  '02h',
  '03h',
  '04h',
  '05h',
  '06h',
  '07h',
  '08h',
  '09h',
  '10h',
  '11h',
  '12h',
  '13h',
  '14h',
  '15h',
  '16h',
  '17h',
  '18h',
  '19h',
  '20h',
  '21h',
  '22h',
  '23h',
];

interface HourByHourProps {
  day: string;
  isOrder: boolean;
}

function HourByHour({ day, isOrder }: HourByHourProps) {
  const reprocessed = useReportFilterFlagFetchStore(
    state => state.withReprocessedFetch,
  );
  const inReprocessing = useReportFilterFlagFetchStore(
    state => state.inReprocessingFetch,
  );
  const engagement = useReportFilterFlagFetchStore(
    state => state.withEngagementFetch,
  );

  const { data } = HourByHourFetch(
    day,
    reprocessed,
    inReprocessing,
    engagement,
    isOrder,
  );

  const info = {
    labels,
    datasets: [
      {
        ...dataSetsBase,
        label: format(parseISO(day), 'dd/MM'),
        backgroundColor: shade(0.2, palette.d),
        borderColor: palette.d,
        pointBorderColor: palette.d,
        pointBackgroundColor: palette.d,
        pointHoverBackgroundColor: palette.d,
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        borderWidth: 5,
        data: data ? data['d-0'] : [],
      },
      {
        ...dataSetsBase,
        label: 'D-1',
        backgroundColor: shade(0.2, palette.d_1),
        borderColor: palette.d_1,
        pointBorderColor: palette.d_1,
        pointBackgroundColor: palette.d_1,
        pointHoverBackgroundColor: palette.d_1,
        pointHoverBorderColor: 'rgba(220,220,220,1)',

        data: data ? data['d-1'] : [],
      },
      {
        ...dataSetsBase,
        label: 'D-7',
        backgroundColor: shade(0.2, palette.d_7),
        borderColor: palette.d_7,
        pointBorderColor: palette.d_7,
        pointBackgroundColor: palette.d_7,
        pointHoverBackgroundColor: palette.d_7,
        pointHoverBorderColor: 'rgba(220,220,220,1)',

        data: data ? data['d-7'] : [],
      },
      {
        ...dataSetsBase,
        label: 'D-14',
        backgroundColor: shade(0.2, palette.d_14),
        borderColor: palette.d_14,
        pointBorderColor: palette.d_14,
        pointBackgroundColor: palette.d_14,
        pointHoverBackgroundColor: palette.d_14,
        pointHoverBorderColor: 'rgba(220,220,220,1)',

        data: data ? data['d-14'] : [],
      },
      {
        ...dataSetsBase,
        label: 'D-21',
        backgroundColor: shade(0.2, palette.d_21),
        borderColor: palette.d_21,
        pointBorderColor: palette.d_21,
        pointBackgroundColor: palette.d_21,
        pointHoverBackgroundColor: palette.d_21,
        pointHoverBorderColor: 'rgba(220,220,220,1)',

        data: data ? data['d-21'] : [],
      },
      {
        ...dataSetsBase,
        label: 'D-28',
        backgroundColor: shade(0.2, palette.d_28),
        borderColor: palette.d_28,
        pointBorderColor: palette.d_28,
        pointBackgroundColor: palette.d_28,
        pointHoverBackgroundColor: palette.d_28,
        pointHoverBorderColor: 'rgba(220,220,220,1)',

        data: data ? data['d-28'] : [],
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          minRotation: 0,
        },
      },
    },
  };
  return (
    <div style={{ marginTop: 25, width: '100%', maxHeight: 350 }}>
      <h2>Hora x Hora {isOrder ? '- Pedidos' : ' - Vendas'}</h2>
      <Line data={info} height={300} options={options} />
    </div>
  );
}

export default HourByHour;
