import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import {
  PackageByPlanFetch,
  PackageWithUnratedFetch,
} from 'fetches/packageFetch';
import { useConfigurationPackageStore } from 'store/configurationPackageStore';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from 'hooks/auth';
import { gestaoPacotes } from 'constants/permissions';
import { ActivePlansFetch } from 'fetches/activePlansFetch';
import { useStyles } from '../styles';
import FormPackageByPlan from './FormPackageByPlan';
import FormPDF from './FormPDF';

function FormOfferDefinition() {
  const configurationStore = useConfigurationPackageStore(
    state => state.configuration,
  );
  const setConfigurationStore = useConfigurationPackageStore(
    state => state.setConfigurationPackage,
  );

  const [showAlertPackagesUnrated, setShowAlertPackagesUnrated] =
    useState(false);
  const [showAlertWithPermission, setShowAlertWithPermission] = useState(false);

  const planSelected = configurationStore.plano_id || 0;

  const { data: plans } = ActivePlansFetch();

  const { data: packageByPlan } = PackageByPlanFetch({
    plano_id: configurationStore.plano_id || 0,
  });

  const { data: packageWithUnrated } = PackageWithUnratedFetch();

  const classes = useStyles();

  const navigate = useNavigate();

  const { hasPermission } = useAuth();

  const findPlanSelectedByID = plans?.find(plan => plan.id === planSelected);

  useEffect(() => {
    const verifyPackageWithUnrated = packageWithUnrated || false;
    setShowAlertPackagesUnrated(verifyPackageWithUnrated);

    const shouldShow = hasPermission(gestaoPacotes);
    setShowAlertWithPermission(shouldShow);
  }, [hasPermission, packageWithUnrated]);

  const handleChangeStart = (value: Date) => {
    const start = value ? value.toString() : '';

    setConfigurationStore({ ...configurationStore, inicio: start });
  };
  const handleChangeEnd = (value: Date) => {
    const end = value ? value.toString() : '';

    setConfigurationStore({ ...configurationStore, fim: end });
  };

  const handleChangeName = (value: string) =>
    setConfigurationStore({ ...configurationStore, nome: value });

  const handleChangePlanId = (value: string) => {
    const planoId = parseInt(value, 10);
    setConfigurationStore({ ...configurationStore, plano_id: planoId });
  };

  const handleChangeWithPortability = (value: boolean) => {
    setConfigurationStore({
      ...configurationStore,
      mostrar_bonus_portabilidade: value,
    });
  };
  const handleChangeWithDedicated = (value: boolean) => {
    setConfigurationStore({
      ...configurationStore,
      mostrar_bonus_dedicado: value,
    });
  };

  const handleChangeUploadPDFAdicionalPackage = (files: FileList | null) => {
    if (files) {
      setConfigurationStore({
        ...configurationStore,
        promotionalFile: files[0],
      });
    }
  };

  return (
    <>
      <Box className={classes.box}>
        <DesktopDateTimePicker
          renderInput={parameters => (
            <TextField {...parameters} fullWidth size="small" />
          )}
          label="Início"
          inputFormat="dd/MM/yyyy HH:mm:ss"
          InputAdornmentProps={{ position: 'end' }}
          value={new Date(configurationStore.inicio)}
          onChange={e => handleChangeStart(e as Date)}
        />

        <DesktopDateTimePicker
          renderInput={parameters => (
            <TextField
              {...parameters}
              fullWidth
              className={classes.endDateTimePicker}
              size="small"
            />
          )}
          label="Fim"
          minDate={new Date(configurationStore.inicio)}
          inputFormat="dd/MM/yyyy HH:mm:ss"
          InputAdornmentProps={{ position: 'end' }}
          value={new Date(configurationStore.fim)}
          onChange={e => handleChangeEnd(e as Date)}
        />
      </Box>
      <Box className={classes.box}>
        <TextField
          fullWidth
          label="Nome"
          margin="dense"
          name="name"
          value={configurationStore.nome || ''}
          size="small"
          onChange={e => handleChangeName(e.target.value)}
          variant="outlined"
          error={configurationStore.nome.length > 100}
          helperText={
            configurationStore.nome.length > 100 && 'Máximo 100 caracteres'
          }
        />
        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          fullWidth
          className={classes.formControl}
        >
          <InputLabel htmlFor="Plano" focused>
            Plano
          </InputLabel>
          <Select
            native
            value={planSelected || 0}
            onChange={e => handleChangePlanId(e.target.value as string)}
            label="Plano"
          >
            <option aria-label="Nenhum plano" value="" />
            {plans?.map(plano => (
              <option key={plano.id} aria-label="plan" value={plano.id}>
                {plano.tim_id} - {plano.nome}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box className={classes.box}>
        <FormPDF
          handleChangeUploadPDFAdicionalPackage={
            handleChangeUploadPDFAdicionalPackage
          }
        />
      </Box>
      {showAlertPackagesUnrated && showAlertWithPermission && (
        <Box className={classes.boxAlert}>
          <Box className={classes.boxText}>
            <ErrorIcon />
            <Typography variant="body2" className={classes.typographyText}>
              Há novos pacotes sincronizados na API que precisam ser
              classificados.
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonAccess}
            onClick={() => navigate('/gestao-pacotes')}
          >
            Acessar
          </Button>
        </Box>
      )}

      <FormPackageByPlan
        planSelected={planSelected}
        gbPlan={findPlanSelectedByID?.gb || 0}
        packageByPlan={packageByPlan}
        handleChangeWithPortability={handleChangeWithPortability}
        handleChangeWithDedicated={handleChangeWithDedicated}
      />
    </>
  );
}

export default FormOfferDefinition;
