import React, { useCallback, useEffect, useState } from 'react';
import { format, isAfter, isBefore, parseISO } from 'date-fns';

import { IconButton, Skeleton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { AddBadge, Badge } from '../Item/styles';
import {
  Container,
  Data,
  DataLabel,
  DataText,
  DataTextName,
  Row,
} from './styles';
import PopperActions from '../PopperActions';
import { ItemProps } from '../Item';

function MobileItem({ data, loading, updateConfigurations }: ItemProps) {
  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [isCurrent, setIsCurrent] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    }

    const now = new Date();
    const start =
      typeof data.inicio === 'string' ? parseISO(data.inicio) : data.inicio;
    const end = typeof data.fim === 'string' ? parseISO(data.fim) : data.fim;
    const isBetween = isAfter(now, start) && isBefore(now, end);

    setIsCurrent(isBetween);
  }, [data]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
    setOpen(previousOpen => !previousOpen);
  }, []);

  const handleClickAway = () => {
    setOpen(false);
  };

  const canBeOpen = open && Boolean(anchorElement);
  const id = canBeOpen ? `popperAction_${data?.id}` : undefined;

  const planGb = data?.plan ? data?.plan.gb : 0;
  const flashSaleGb = data?.flash_sale ? data.flash_sale.gb : 0;
  const portabilityGb = data?.portability ? data.portability.gb : 0;
  const dedicatedGb = data?.dedicated ? data.dedicated.gb : 0;

  let totalGb = planGb + flashSaleGb;
  totalGb = data?.mostrar_bonus_portabilidade
    ? portabilityGb + totalGb
    : totalGb;
  totalGb = data?.mostrar_bonus_dedicado ? dedicatedGb + totalGb : totalGb;

  return (
    <Container>
      <Row>
        <Data>
          <DataLabel>Nome</DataLabel>
          {loading ? (
            <Skeleton width={200} />
          ) : (
            <DataTextName>{data?.nome}</DataTextName>
          )}
        </Data>
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
      </Row>
      <Row>
        <Data>
          <DataLabel>Plano</DataLabel>
          {loading ? (
            <Skeleton width={80} />
          ) : (
            <DataText>+{planGb}GB Promocionais</DataText>
          )}
        </Data>
        <Data>
          <DataLabel>Flash Sales</DataLabel>
          {loading ? (
            <Skeleton width={80} />
          ) : (
            <DataText>{flashSaleGb}GB</DataText>
          )}
        </Data>
      </Row>
      <Row>
        <Data>
          <DataLabel>Portabilidade</DataLabel>
          {loading ? (
            <Skeleton width={80} />
          ) : (
            <DataText>
              {portabilityGb}GB{' '}
              {data?.mostrar_bonus_portabilidade ? <AddBadge>+</AddBadge> : ''}
            </DataText>
          )}
        </Data>
        <Data>
          <DataLabel>Dedicado</DataLabel>
          {loading ? (
            <Skeleton width={80} />
          ) : (
            <DataText>
              {dedicatedGb}GB{' '}
              {data?.mostrar_bonus_dedicado ? <AddBadge>+</AddBadge> : ''}
            </DataText>
          )}
        </Data>
      </Row>
      <Row>
        <Data>
          <DataLabel>Total</DataLabel>
          {loading ? <Skeleton width={80} /> : <DataText>{totalGb}GB</DataText>}
        </Data>
        <Data>
          <DataLabel>Data Início</DataLabel>
          {loading ? (
            <Skeleton width={80} />
          ) : (
            <DataText>
              {data && format(parseISO(data.inicio), 'dd/MM/yyyy - HH:mm')}
            </DataText>
          )}
        </Data>
      </Row>
      <Row>
        <Data>
          <DataLabel>Data Fim</DataLabel>
          {loading ? (
            <Skeleton width={80} />
          ) : (
            <DataText>
              {data && format(parseISO(data.fim), 'dd/MM/yyyy - HH:mm')}{' '}
            </DataText>
          )}
        </Data>
        <Data style={{ marginTop: 15 }}>
          {isCurrent ? (
            <Badge style={{ marginRight: 44 }}>vigente</Badge>
          ) : (
            <span style={{ visibility: 'hidden', marginRight: 82 }}>N/A</span>
          )}
        </Data>
      </Row>
      <PopperActions
        id={id || ''}
        open={open}
        configurationId={data?.id || 0}
        configurationName={data?.nome || ''}
        anchorElement={anchorElement}
        handleClick={handleClick}
        handleClickAway={handleClickAway}
        updateConfigurations={updateConfigurations}
      />
    </Container>
  );
}

export default MobileItem;
