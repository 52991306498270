import React, { useEffect, useState } from 'react';
import Close from '@mui/icons-material/Close';
import {
  cell as cellFormatter,
  cep as cepFormatter,
  cpf as cpfFormatter,
  date as dateFormatter,
  dateTime as dateTimeFormatter,
  iccid as iccidFormatter,
} from 'util/formatter';
import {
  ActionDrawer,
  CloseButton,
  ContainerDrawer,
  DividerDrawer,
  SessionTitle,
} from 'components/StyledComponents';
import { useAuth } from 'hooks/auth';
import { vendaEditarDados } from 'constants/permissions';
import { Grid, IconButton } from '@mui/material';
import TextInfo from 'pages/Sale/InfoSale/TextInfo';
import Status from 'pages/Sale/InfoSale/Status';
import EditSaleDataForm from 'pages/Sale/InfoSale/EditSaleDataForm';
import EditCustomerForm from 'pages/Sale/InfoSale/EditCustomerForm';
import EditAdressCustomerForm from 'pages/Sale/InfoSale/EditAddressCustomerForm';
import LogHighlighter from 'pages/Sale/InfoSale/LogHighlighter';
import { Sale } from 'pages/Sale/InfoSale/types';
import DeclareAsUntreatableForm from './DeclareAsUntreatableForm';
import ReprocessSaleForm from './ReprocessSaleForm';

export interface InfoSaleFormProps {
  sale?: Sale;
  setSale(sale: Sale): void;
  removeSale(sale: Sale): void;
  closeDrawer(): void;
  updateSelectedSale?(sale: Sale): void;
  showActionInSale?: boolean;
  updateReprocessSale(): void;
}

function InfoSale({
  sale,
  setSale,
  closeDrawer,
  updateSelectedSale,
  showActionInSale = true,
  removeSale,
  updateReprocessSale,
}: InfoSaleFormProps) {
  const [status, setStatus] = useState('');
  const [reprocessada, setReprocessada] = useState(false);

  const [showButtonEditCustomerData, setShowButtonEditCustomerData] =
    useState(false);
  const [showButtonEditSaleData, setShowButtonEditSaleData] = useState(false);

  const { hasPermission } = useAuth();

  useEffect(() => {
    setStatus(sale?.status || '');
    setReprocessada(sale?.reprocessada || false);
    sale && updateSelectedSale && updateSelectedSale?.(sale);
  }, [sale, updateSelectedSale]);

  useEffect(() => {
    const shouldShow = hasPermission(vendaEditarDados);

    setShowButtonEditSaleData(shouldShow);
    setShowButtonEditCustomerData(shouldShow);
  }, [hasPermission]);

  return (
    <ContainerDrawer>
      <Grid container spacing={2}>
        <CloseButton onClick={() => closeDrawer()}>
          <IconButton>
            <Close />
          </IconButton>
        </CloseButton>
        <Grid item xs={12}>
          <Status loading={!sale} status={status} />
        </Grid>

        {showActionInSale && sale && (
          <Grid item xs={12}>
            <DeclareAsUntreatableForm
              sale={sale}
              setSale={setSale}
              removeSale={removeSale}
              closeDrawer={closeDrawer}
            />
          </Grid>
        )}

        {showActionInSale &&
          sale &&
          (!reprocessada || sale.status === 'LEAD_TRATAVEL') && (
            <Grid item xs={12}>
              <ReprocessSaleForm
                sale={sale}
                setSale={setSale}
                updateReprocessSale={updateReprocessSale}
              />
            </Grid>
          )}
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Dados do Pedido
            {sale && showButtonEditSaleData && (
              <ActionDrawer>
                <EditSaleDataForm sale={sale} setSale={setSale} />
              </ActionDrawer>
            )}
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextInfo
            loading={!sale}
            label="Motivo Tratamento"
            info={sale?.motivo_tratamento || '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Plano"
            info={sale?.plan.nome}
            canCopy
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Fidelidade"
            info={sale?.loyalty?.descricao}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Segmento"
            info={sale?.plan.segmento}
            canCopy
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo loading={!sale} label="Tipo" info={sale?.tipo} />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo loading={!sale} label="DDD" info={sale?.ddd} />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="MSISDN"
            info={cellFormatter(sale?.msisdn)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInfo
            loading={!sale}
            label="ICCID"
            info={iccidFormatter(sale?.iccid) || '-'}
            canCopy
            onlyNumbers
          />
        </Grid>
        {sale?.tipo === 'PORTABILIDADE' && (
          <>
            <Grid item xs={12} md={6}>
              <TextInfo
                loading={!sale}
                label="Número Provisório"
                info={cellFormatter(sale?.provisorio) || '-'}
                canCopy
                onlyNumbers
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInfo
                loading={!sale}
                label="Data Agendamento"
                info={dateTimeFormatter(sale?.data_portabilidade) || '-'}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Valor"
            info={Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(sale?.valor || 0)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Vencimento"
            info={sale?.vencimento}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInfo
            loading={!sale}
            label="Forma de Pagamento"
            info={sale?.forma_pagamento}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Protocolo"
            info={sale?.protocolo || '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Data ativação"
            info={
              sale?.data_ativacao ? dateTimeFormatter(sale?.data_ativacao) : '-'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Código de rastreamento"
            info={sale?.codigo_rastreamento || '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Oferta Flash"
            info={
              sale?.configuration_package_with_trashed?.flash_sale?.gb
                ? `+ ${sale?.configuration_package_with_trashed?.flash_sale?.gb}GB Promocionais`
                : '-'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Oferta Portabilidade"
            info={
              sale?.tipo === 'PORTABILIDADE' &&
              sale?.configuration_package_with_trashed?.portability?.gb
                ? `+ ${sale?.configuration_package_with_trashed?.portability?.gb}GB`
                : '-'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Oferta Dedicada"
            info={
              sale?.configuration_package_with_trashed?.dedicated?.gb
                ? `+ ${sale?.configuration_package_with_trashed?.dedicated?.gb}GB`
                : '-'
            }
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Origem"
            info={sale?.utm_source || '-'}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Mídia"
            info={sale?.utm_medium || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Campanha"
            info={sale?.utm_campaign || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Venda ID"
            info={sale?.id.toString() || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Motivo Cancelamento"
            info={sale?.motivo_cancelamento || '-'}
          />
        </Grid>
        <Grid item xs={6} md={12}>
          <TextInfo
            loading={!sale}
            label="IP do cliente"
            info={(sale && sale.ip) || '-'}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Dados do Cliente
            {sale && showButtonEditCustomerData && (
              <ActionDrawer>
                <EditCustomerForm sale={sale} setSale={setSale} />
              </ActionDrawer>
            )}
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo loading={!sale} label="Nome" info={sale?.customer?.nome} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="CPF"
            info={sale && cpfFormatter(sale.customer.cpf)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo loading={!sale} label="Email" info={sale?.customer.email} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInfo
            loading={!sale}
            label="Data de Nascimento"
            info={dateFormatter(sale?.customer.nascimento)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Nome da Mãe"
            info={sale?.customer.filiacao}
            canCopy
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Telefone Pessoal"
            info={cellFormatter(sale?.customer.telefone)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Endereço
            {showButtonEditSaleData && sale && (
              <ActionDrawer>
                <EditAdressCustomerForm sale={sale} setSale={setSale} />
              </ActionDrawer>
            )}
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="CEP"
            info={cepFormatter(sale?.cep)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo loading={!sale} label="Estado" info={sale?.uf} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInfo loading={!sale} label="Cidade" info={sale?.cidade} />
        </Grid>
        <Grid item xs={6}>
          <TextInfo loading={!sale} label="Rua" info={sale?.logradouro} />
        </Grid>
        {sale?.tipo_identificador === 'NUMERO' && (
          <Grid item xs={6} md={3}>
            <TextInfo loading={!sale} label="Número" info={sale?.numero} />
          </Grid>
        )}
        {sale?.tipo_identificador === 'QUADRA_LOTE' && (
          <>
            <Grid item xs={3} md={3}>
              <TextInfo
                loading={!sale}
                label="Quadra"
                info={`Q${sale?.quadra}`}
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <TextInfo loading={!sale} label="Lote" info={`L${sale?.lote}`} />
            </Grid>
          </>
        )}
        {sale?.tipo_identificador === 'SEM_NUMERO' && (
          <Grid item xs={6} md={3}>
            <TextInfo loading={!sale} label="Número" info="SN" />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextInfo loading={!sale} label="Bairro" info={sale?.bairro} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Complemento ( Apto / Bloco / Sala )"
            info={sale?.complemento || '-'}
          />
        </Grid>
        {sale?.logs && sale?.logs.length > 0 && (
          <>
            <Grid item xs={12} style={{ height: 45 }}>
              <SessionTitle>
                Dados da API
                <DividerDrawer />
              </SessionTitle>
            </Grid>
            <Grid item xs={12}>
              {sale?.logs.map(item => (
                <LogHighlighter
                  key={item.id}
                  log={JSON.stringify(item.log, undefined, 2)}
                />
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </ContainerDrawer>
  );
}

export default InfoSale;
