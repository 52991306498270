import { useStylesModal } from 'components/UseStylesModal';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { ButtonProgress } from 'components/StyledComponents';
import { ModalCard } from 'pages/CmsLP/components/DeleteModal/styles';
import { AntSwitch } from '../../../../../../../../components/Switch';

interface ReturnProcessingProps {
  baseId: number;
  fetchBaseData(): void;
}
function ReturnProcessing({ baseId, fetchBaseData }: ReturnProcessingProps) {
  const classes = useStylesModal();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState(0);
  const [all, setAll] = useState(true);

  const { addToast } = useToast();

  const handleClose = () => {
    setOpen(false);
  };

  const handleReturnProcessing = useCallback(() => {
    setLoading(true);

    const body = {
      quantidade: all ? 'all' : number,
    };

    api
      .put(`/core/impactos/resumir-processamento/${baseId}`, body)
      .then(() => {
        fetchBaseData();
        addToast({
          type: 'success',
          title: 'Processamento retornado',
          description: 'O processamento da base foi retornado com sucesso.',
        });
        setLoading(false);
        handleClose();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao retornar processamento',
          description: error?.data?.message,
        });
        setLoading(false);
        handleClose();
      });
  }, [all, number, baseId, fetchBaseData, addToast]);

  useEffect(() => {
    if (all) {
      setNumber(0);
    }
  }, [all]);

  return (
    <>
      <div style={{ marginLeft: 'auto' }}>
        <Button
          type="button"
          variant="contained"
          onClick={() => setOpen(true)}
          disabled={loading}
        >
          Despausar Leads Pausados
        </Button>
      </div>
      <Modal
        aria-labelledby="tile-modal"
        aria-describedby="modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <ModalCard>
            <Typography>Quantos leads você deseja despausar?</Typography>
            <Grid
              md={12}
              xs={12}
              display="flex"
              alignItems="center"
              padding="10px"
            >
              <Grid item md={3} xs={3}>
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    style={{ paddingBottom: 6, fontSize: '12px' }}
                  >
                    Todos
                  </FormLabel>
                  <AntSwitch
                    size="medium"
                    checked={all}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setAll(event.target.checked)
                    }
                    color="secondary"
                  />
                </FormControl>
              </Grid>
              <Grid item md={9} xs={9}>
                <FormControl component="fieldset">
                  <TextField
                    value={number}
                    aria-readonly
                    fullWidth
                    label="Quantidade"
                    margin="dense"
                    name="quantidade"
                    disabled={all}
                    onKeyPress={event => {
                      if (/[0-9]/.test(event.key)) {
                        return;
                      }
                      event.preventDefault();
                    }}
                    onChange={e => setNumber(Number(e.target.value))}
                    variant="outlined"
                    style={{ width: '100%' }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <DialogActions className={classes.dialogActions}>
              <Button variant="outlined" onClick={() => handleClose()}>
                Voltar
              </Button>
              <Button
                onClick={handleReturnProcessing}
                variant="contained"
                disabled={loading}
              >
                Despausar
                {loading && <ButtonProgress size={24} />}
              </Button>
            </DialogActions>
          </ModalCard>
        </Fade>
      </Modal>
    </>
  );
}

export default ReturnProcessing;
