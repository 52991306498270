import { Card, Divider, Skeleton } from '@mui/material';
import { usePlanStore } from 'store/planStore';
import PackageListItem from './PackagesListItem';
import { CardNoPackage, TitlePackage } from './styles';
import { Package } from '../../../../models/packages';

interface PackagesListProps {
  loading?: boolean;
}

function PackagesList({ loading }: PackagesListProps) {
  const planStore = usePlanStore(state => state.plan);
  const setPlanStore = usePlanStore(state => state.setPlan);

  const packagesFlashSaleFiltered = planStore.pacotes?.filter(
    (pacote: Package) => pacote.categoria === 'FLASHSALE',
  );

  const packagesPortabilityFiltered = planStore.pacotes?.filter(
    (pacote: Package) => pacote.categoria === 'PORTABILIDADE',
  );

  const handleRemoveItem = (id: string) => {
    const newPackages = planStore.pacotes.filter(
      fidelidade => fidelidade.tim_id !== id,
    );
    setPlanStore({ ...planStore, pacotes: newPackages });
  };
  return (
    <>
      <Divider style={{ marginLeft: 16, marginRight: 16 }} />
      <TitlePackage>Flash Sales</TitlePackage>

      <Card style={{ margin: '8px 16px 16px' }}>
        {loading ? (
          <Card>
            <Skeleton width="30%" />
            <Skeleton width="30%" />
            <Skeleton width="30%" />
          </Card>
        ) : packagesFlashSaleFiltered.length > 0 ? (
          packagesFlashSaleFiltered.map(pacotes => (
            <PackageListItem
              key={pacotes.tim_id}
              nome={pacotes.nome}
              onRemoveItem={() => handleRemoveItem(pacotes.tim_id)}
            />
          ))
        ) : (
          <CardNoPackage>O plano não possui pacotes disponíveis.</CardNoPackage>
        )}
      </Card>
      <Divider style={{ margin: '0px 16px 0px 16px', paddingTop: '14px' }} />
      <Divider style={{ margin: '0px 16px 0px 16px', paddingTop: '14px' }} />
      <TitlePackage>Portabilidade</TitlePackage>

      <Card style={{ margin: '8px 16px 16px' }}>
        {loading ? (
          <Card>
            <Skeleton width="30%" />
            <Skeleton width="30%" />
            <Skeleton width="30%" />
          </Card>
        ) : packagesPortabilityFiltered.length > 0 ? (
          packagesPortabilityFiltered.map(pacotes => (
            <PackageListItem
              key={pacotes.tim_id}
              nome={pacotes.nome}
              onRemoveItem={() => handleRemoveItem(pacotes.tim_id)}
            />
          ))
        ) : (
          <CardNoPackage>O plano não possui pacotes disponíveis.</CardNoPackage>
        )}
      </Card>
    </>
  );
}

export default PackagesList;
