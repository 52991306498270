interface AttributesCategoryTitleSetup {
  label: string;
}

interface CategoryTitleSetup {
  FLASHSALE: AttributesCategoryTitleSetup;
  PORTABILIDADE: AttributesCategoryTitleSetup;
  DEDICADO: AttributesCategoryTitleSetup;

  [key: string]: AttributesCategoryTitleSetup;
}

const categoryTitleSetup: CategoryTitleSetup = {
  FLASHSALE: {
    label: 'Flash Sales',
  },
  PORTABILIDADE: {
    label: 'Portabilidade',
  },
  DEDICADO: {
    label: 'Dedicado',
  },
};

export default categoryTitleSetup;
