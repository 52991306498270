import { Box, Card, Grid, Icon, Skeleton, Typography } from '@mui/material';
import React from 'react';
import useStyles, { BoxIcon, Content } from './styles';
import { cardConfig } from './cardConfig';
import RelevantDataPanel from './RelevantDataPanel';
import { HealthCheckStatusFetch } from '../../../../fetches/healthCheckStatusFetch';

interface HealthCheckPanelProps {
  mechanism: string;
}
function HealthCheckPanel({ mechanism }: HealthCheckPanelProps) {
  const { data: healthData } = HealthCheckStatusFetch(mechanism);
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      sx={{
        border: 2,
        borderColor: cardConfig[healthData?.status ?? 'OFFLINE'].borderColor,
      }}
    >
      {healthData ? (
        <>
          <Content color={cardConfig[healthData.status].contentColor}>
            <Grid item className={classes.contentTotal}>
              <Typography
                className={classes.title}
                color={cardConfig[healthData.status].titleColor}
              >
                {healthData.title}
              </Typography>
              <RelevantDataPanel relevantData={healthData.relevantData} />
            </Grid>
          </Content>
          <BoxIcon color={cardConfig[healthData.status].boxIconColor}>
            <Box
              padding="15px"
              display="flex"
              flex-direction="row"
              justifyContent="center"
              alignItems="center"
              gap="10px"
            >
              <Icon
                component={cardConfig[healthData.status].icon}
                fontSize="large"
                sx={{
                  color: cardConfig[healthData.status].colorIcon,
                }}
              />
              <Typography
                className={classes.subtitle}
                color={cardConfig[healthData.status].subtitleColor}
                variant="subtitle1"
              >
                {cardConfig[healthData.status].title}
              </Typography>
            </Box>
          </BoxIcon>
        </>
      ) : (
        <Skeleton animation="wave" height="134px" width="100%" />
      )}
    </Card>
  );
}

export default HealthCheckPanel;
