import { format } from 'date-fns';
import { ConfigurationByIdPackage } from 'models/configurationsPackage';
import create from 'zustand';

const defaultConfigurationPackage: ConfigurationByIdPackage = {
  id: 0,
  nome: '',
  plano_id: 0,
  pacote_flashsale: 0 || null,
  pacote_portabilidade: 0 || null,
  pacote_dedicado: 0 || null,
  mostrar_bonus_portabilidade: false,
  mostrar_bonus_dedicado: false,
  promotionalFile: null,
  path_regulamento_pacotes_adicionais: '',
  inicio: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
  fim: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
};

type ConfigurationPackageStore = {
  configuration: ConfigurationByIdPackage;
  setConfigurationPackage(configuration: ConfigurationByIdPackage): void;
  clearConfigurationPackageStore(): void;
};

export const useConfigurationPackageStore = create<ConfigurationPackageStore>(
  set => ({
    configuration: defaultConfigurationPackage,
    setConfigurationPackage(configuration: ConfigurationByIdPackage) {
      set(state => ({ ...state, configuration }));
    },

    clearConfigurationPackageStore() {
      set(state => ({
        ...state,
        configuration: defaultConfigurationPackage,
      }));
    },
  }),
);
