import { Box, Grid, Typography } from '@mui/material';
import { BoxDescriptionType } from 'pages/ManagementReport/styles';
import useStyles from './styles';
import { cardConfig, RelevantData } from '../cardConfig';

interface RelevantDataProps {
  relevantData: RelevantData[];
}

function RelevantDataPanel({ relevantData }: RelevantDataProps) {
  const classes = useStyles();
  return (
    <Grid justifyContent="flex-start" className={classes.boxRelevantData}>
      {relevantData.map(data => (
        <Grid item>
          <BoxDescriptionType>
            <Box className={classes.boxCount}>
              <Typography
                className={classes.typeIndicatorText}
                color={cardConfig[data.status].colorRelevantDataTitle}
              >
                {data.title}
              </Typography>
              <Typography
                className={classes.typeIndicatorText}
                color={cardConfig[data.status].colorRelevantDataValue}
              >
                {data.value}
              </Typography>
            </Box>
          </BoxDescriptionType>
        </Grid>
      ))}
    </Grid>
  );
}

export default RelevantDataPanel;
