import { useStylesModal } from 'components/UseStylesModal';
import { useCallback, useState } from 'react';
import { Button, DialogActions, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { ButtonProgress } from 'components/StyledComponents';
import { ModalCard } from 'pages/CmsLP/components/DeleteModal/styles';

interface CancelProcessingProps {
  baseId: number;
  fetchBaseData(): void;
}
function CancelProcessing({ baseId, fetchBaseData }: CancelProcessingProps) {
  const classes = useStylesModal();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelProcessing = useCallback(() => {
    setLoading(true);
    api
      .put(`/core/impactos/pausar-processamento/${baseId}`)
      .then(() => {
        fetchBaseData();
        addToast({
          type: 'success',
          title: 'Processamento cancelado',
          description: 'O processamento da base foi cancelado com sucesso.',
        });
        setLoading(false);
        handleClose();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao cancelar processamento',
          description: error?.data?.message,
        });
        setLoading(false);
        handleClose();
      });
  }, [baseId, fetchBaseData, addToast]);

  return (
    <>
      <div style={{ marginLeft: 'auto' }}>
        <Button
          type="button"
          className={classes.buttonCancel}
          variant="outlined"
          onClick={() => setOpen(true)}
          disabled={loading}
        >
          Pausar Leads Não Processados
        </Button>
      </div>
      <Modal
        aria-labelledby="tile-modal"
        aria-describedby="modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <ModalCard>
            <Typography>Você deseja mesmo pausar esta base?</Typography>
            <DialogActions className={classes.dialogActions}>
              <Button variant="outlined" onClick={() => handleClose()}>
                Voltar
              </Button>
              <Button
                onClick={handleCancelProcessing}
                variant="outlined"
                color="error"
                disabled={loading}
              >
                Pausar Leads Não Processados
                {loading && <ButtonProgress size={24} />}
              </Button>
            </DialogActions>
          </ModalCard>
        </Fade>
      </Modal>
    </>
  );
}

export default CancelProcessing;
