import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

export const cleanHtmlString = (value: string) => {
  const cleanString = DOMPurify.sanitize(value, {
    USE_PROFILES: { html: true },
  });

  return parse(cleanString);
};
