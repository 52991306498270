import styled from 'styled-components';
import { Button, CircularProgress } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 14px 14px 0;

  background: #fff;
  transition: background 0.2s;

  position: relative;

  &:hover {
    background: #d0edfb;
    cursor: pointer;
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 16px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 15px;
  position: relative;
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
`;

export const Divider = styled.div`
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const DividerDrawer = styled.div`
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Data = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px;
`;

export const DataTextName = styled.span`
  min-width: 150px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

export const DataText = styled.span`
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

export const Card = styled.div`
  padding: 5px 15px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
`;

export const List = styled.div`
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1));
  border-radius: 6px;
  width: 100%;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const MobileData = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileDataLabel = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
`;

export const MobileDataText = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 15px;

  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;

  button {
    margin-left: 4px;
    height: 19px;
    border: none;
    background: transparent;
  }
`;

export const MobileTextName = styled.span`
  width: 250px;
  font-size: 15px;
  word-break: break-word;
`;

export const CloseButton = styled.div`
  margin-left: auto;
  button {
    padding-right: 0;
    padding-top: 0;
    svg {
      color: #b3b3b3;
      width: 30px;
      height: 30px;
    }
  }
`;

export const Infos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

export const LeftInfo = styled.div`
  width: 48%;
`;
export const RightInfo = styled.div`
  width: 48%;
`;

export const SessionTitle = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(15, 35, 115, 0.8);
  margin-bottom: 5px;
  padding: 10px 0 10px 0;
  display: flex;
  flex-wrap: wrap;
`;
export const ButtonProgress = styled(CircularProgress)`
  position: absolute;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  > div,
  button {
    width: 48%;
  }
`;

export const ContainerDrawer = styled.div`
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 650px;

  @media (max-width: 768px) {
    max-width: 100vw;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const useStylesActionButtons = makeStyles(() => ({
  iconEdit: {
    color: 'rgba(204, 204, 204, 1)',
    display: 'flex',
    alignItems: 'center',
    minWidth: 30,
    '& svg': {
      height: 22,
      width: 22,
    },
  },
  iconDelete: {
    color: 'rgba(201, 42, 42, 1)',
    display: 'flex',
    alignItems: 'center',
    minWidth: 30,
    '& svg': {
      height: 22,
      width: 22,
    },
  },
  iconView: {
    color: 'rgba(55, 71, 79, 0.6)',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    minWidth: 20,
    '& svg': {
      height: 22,
      width: 22,
    },
  },
  buttonUpload: {
    color: '#B3B3B3',
    fontWeight: 400,
    padding: 8,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: '#B3B3B3',
    },
  },
  pdfFile: {
    fontWeight: 400,
    padding: 8,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '& .MuiSvgIcon-root': {
      color: '#B3B3B3',
    },
  },
}));

export const CancelButton = withStyles(() => ({
  root: {
    color: '#C92A2A',
    paddingLeft: 0,
  },
}))(Button);

export const ActionDrawer = styled.div`
  margin-left: auto;
  div {
    padding: 0px;
    button {
      width: 20px;
      height: 20px;
      color: rgba(0, 0, 0, 0.8);
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
