import palette from 'theme/palette';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';

export interface HealthData {
  title: string;
  status: Status;
  relevantData: RelevantData[];
}
export interface RelevantData {
  title: string;
  value: string;
  status: Status;
}

interface CardConfig {
  title: string;
  icon: React.ElementType;
  colorIcon: string;
  colorRelevantDataTitle: string;
  colorRelevantDataValue: string;
  titleColor: string;
  subtitleColor: string;
  boxIconColor: string;
  contentColor: string;
  borderColor: string;
}

export type Status = 'SUCCESS' | 'ALERT' | 'FAILURE' | 'OFFLINE';

interface CardTypes {
  SUCCESS: CardConfig;
  ALERT: CardConfig;
  FAILURE: CardConfig;
  OFFLINE: CardConfig;
  [key: string]: CardConfig;
}

export const cardConfig: CardTypes = {
  SUCCESS: {
    title: 'Ok',
    icon: VerifiedOutlinedIcon,
    titleColor: palette.black,
    subtitleColor: palette.white,
    colorIcon: palette.white,
    colorRelevantDataTitle: palette.black,
    colorRelevantDataValue: palette.success.dark,
    boxIconColor: palette.success.dark,
    contentColor: palette.success.light,
    borderColor: palette.success.dark,
  },
  ALERT: {
    title: 'Alguns Problemas',
    icon: MonitorHeartOutlinedIcon,
    titleColor: palette.black,
    subtitleColor: palette.white,
    colorIcon: palette.white,
    colorRelevantDataTitle: palette.black,
    colorRelevantDataValue: palette.warning.dark,
    boxIconColor: palette.warning.dark,
    contentColor: palette.warning.light,
    borderColor: palette.warning.dark,
  },
  FAILURE: {
    title: 'Falha',
    icon: ReportGmailerrorredIcon,
    titleColor: palette.black,
    subtitleColor: palette.white,
    colorIcon: palette.white,
    colorRelevantDataTitle: palette.black,
    colorRelevantDataValue: palette.error.dark,
    boxIconColor: palette.error.dark,
    contentColor: palette.error.light,
    borderColor: palette.error.dark,
  },
  OFFLINE: {
    title: 'Offline',
    icon: WebAssetOffIcon,
    titleColor: palette.black,
    subtitleColor: palette.white,
    colorIcon: palette.white,
    colorRelevantDataTitle: palette.black,
    colorRelevantDataValue: palette.offline.dark,
    boxIconColor: palette.offline.dark,
    contentColor: palette.offline.light,
    borderColor: palette.offline.dark,
  },
};
