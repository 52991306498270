import {
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
} from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import { format } from 'date-fns';
import { useToast } from 'hooks/toast';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { useConfigurationPackageStore } from 'store/configurationPackageStore';
import FormOfferDefinition from '../OfferDefinitionList/FormOfferDefinition';
import { useStyles } from '../OfferDefinitionList/styles';

function NewOfferDefinition() {
  const configurationStore = useConfigurationPackageStore(
    state => state.configuration,
  );
  const clearForm = useConfigurationPackageStore(
    state => state.clearConfigurationPackageStore,
  );

  const [loading, setLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();
  const { addToast } = useToast();

  useEffect(() => {
    setFormIsValid(
      !!configurationStore.nome &&
        configurationStore.nome.length <= 100 &&
        !!configurationStore.plano_id &&
        !!configurationStore.inicio &&
        !!configurationStore.fim,
    );
  }, [configurationStore]);

  const handleSave = useCallback(() => {
    setLoading(true);

    const formData = new FormData();

    formData.append(
      'configuration_package_form',
      JSON.stringify({
        ...configurationStore,
        inicio: format(
          new Date(configurationStore.inicio),
          'yyyy-MM-dd HH:mm:ss',
        ),
        fim: format(new Date(configurationStore.fim), 'yyyy-MM-dd HH:mm:ss'),
      }),
    );
    if (configurationStore.promotionalFile) {
      formData.append(
        'regulamento_pacotes_adicionais',
        configurationStore.promotionalFile,
      );
    }

    api
      .post(`/core/pacote-configuracoes`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Criação de definição de oferta',
          description: 'Nova oferta definida com sucesso',
        });

        clearForm();
        navigate('/flash-sales/');
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao criar definição de oferta',
          description: error?.data?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [configurationStore, addToast, clearForm, navigate]);

  useEffect(() => {
    window.addEventListener(
      'popstate',
      event => {
        if (event.state) {
          clearForm();
        }
      },
      false,
    );
  });

  return (
    <Card>
      <CardHeader title="Criar definição de oferta" />

      <CardContent>
        <FormOfferDefinition />

        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            onClick={() => {
              clearForm();
              navigate('/flash-sales/');
            }}
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!formIsValid || loading}
            onClick={() => handleSave()}
          >
            Salvar
            {loading && <ButtonProgress size={24} />}
          </Button>
        </DialogActions>
      </CardContent>
    </Card>
  );
}

export default NewOfferDefinition;
