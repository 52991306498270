import useSWR from 'swr';
import api from 'services/api';
import { HourByHour } from 'models/reports';

export const HourByHourFetch = (
  day: string,
  reprocessed: boolean,
  inReprocessing: boolean,
  engagement: boolean,
  isOrder: boolean,
) => {
  const { data, mutate } = useSWR<HourByHour>(
    `hour_by_hour_${day}_${reprocessed}_${inReprocessing}_${engagement}_${isOrder}`,

    async (): Promise<HourByHour> => {
      const params = {
        dia: day,
        trazer_reprocessados: reprocessed ? 1 : 0,
        trazer_em_processamento: inReprocessing ? 1 : 0,
        trazer_engajadas: engagement ? 1 : 0,
        pedidos: isOrder ? 1 : 0,
      };
      const { data: hourByHour } = await api.get(
        '/core/relatorios/dashboards/hora-a-hora',
        { params },
      );
      return hourByHour as HourByHour;
    },
  );
  return { data, mutate };
};
