import TotalizerCard from 'components/TotalizerCard';
import { QueueSizeFetch } from '../../../../fetches/queueSizeFetch';

interface QueueSizeProps {
  isDesktop: boolean;
  key: string;
  title: string;
  connection: string;
  queue: string;
}

function QueueSize({
  isDesktop,
  key,
  title,
  connection,
  queue,
}: QueueSizeProps) {
  const { data: size } = QueueSizeFetch(connection, queue);

  return (
    <TotalizerCard
      key={key}
      title={title}
      total={size?.total || 0}
      isDesktop={isDesktop}
    />
  );
}

export default QueueSize;
