import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useBreakpoint from 'hooks/useBreakpoint';
import { EligibilityReportFetch } from 'fetches/eligibilityReportFetch';
import { Chart, Plugin } from 'chart.js';
import { Graphic } from './styles';
import { Container, Title } from '../styles';

Chart.register(ChartDataLabels);

const plugins: Plugin<'pie'>[] = [ChartDataLabels as Plugin<'pie'>];

interface EligibilityProps {
  start: string;
  end: string;
}

function Eligibility({ start, end }: EligibilityProps) {
  const { data: response } = EligibilityReportFetch(start, end);

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const data = {
    labels: response ? Object.keys(response).map(key => key || 'Sucesso') : [],
    datasets: [
      {
        data: response ? Object.keys(response).map(key => response[key]) : [],
        backgroundColor: [
          'rgba(204, 46, 141, 1)',
          'rgba(54, 79, 199, 1)',
          'rgba(31, 188, 255, 1)',
          'rgba(230, 119, 0, 1)',
          'rgba(201, 42, 42, 1)',
          'rgba(95, 61, 196, 1)',
          'rgba(134, 46, 156, 1)',
          'rgba(5, 12, 39, 1)',
          'rgba(66, 27, 149, 1)',
          'rgba(166, 30, 77, 1)',
          'rgba(43, 138, 62, 1)',
        ],
        borderWidth: 0,
        padding: 10,
      },
    ],
  };

  const alignLegend = isTableOrLess ? 'start' : 'center';

  return (
    <Container>
      <Title>Elegibilidade</Title>
      <Graphic>
        <Pie
          data={data}
          options={{
            layout: {
              padding: {
                top: 20,
                left: 10,
                bottom: 10,
                right: 10,
              },
            },
            plugins: {
              datalabels: {
                anchor: 'end',
                align: 'end',
                offset: 2,
                color: 'rgba(0, 0, 0, 0.4)',
                font: { size: 10 },
                formatter: (value: number) => {
                  const valueCustom = `${value}%`;
                  return valueCustom;
                },
              },
              legend: {
                display: true,
                position: 'bottom',
                align: alignLegend,
                labels: {
                  padding: 10,
                  pointStyle: 'circle',
                  usePointStyle: true,
                  boxWidth: 10,
                  color: '#050C27',
                  textAlign: 'left',
                },
              },
              tooltip: {
                callbacks: {
                  label: context => {
                    const value = `${context.parsed}%`;
                    const labelInfo = `${context.label}: `;

                    return labelInfo + value;
                  },
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
          plugins={plugins}
        />
      </Graphic>
    </Container>
  );
}

export default Eligibility;
